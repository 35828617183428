import { AxiosError } from 'axios';
import { ErrorResponse } from '../redux/types';

export const handleAxiosError = (error: AxiosError<ErrorResponse> | null, alertClass: string = 'alert alert-danger') => {
    if (!error || !error.response || !error.response.data) return null;

    const errors = error.response.data.errors || {};
    return Object.keys(errors).map((key) => {
        const errorMessages: string[] = Array.isArray(errors[key]) ? errors[key] : [errors[key]];
        return (
            <div key={key} className={alertClass}>
                {errorMessages.map((err: string, index: number) => (
                    <div key={index}>
                        {err}
                    </div>
                ))}
            </div>
        );
    });
};
