import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import {Pagination} from "react-bootstrap";
import { fetchEmployeesAction, deleteEmployeeAction } from '../../redux/actions/employeeActions';
import { RootState } from '../../redux/reducers';
import { Employee } from '../../redux/types';
import { useNavigate } from 'react-router-dom';
import { DataNode } from 'rc-tree/es/interface';
import './EmployeeTree.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ThunkDispatch } from 'redux-thunk';
import { EmployeeActionTypes } from '../../redux/actions/employeeActions';
import {deleteEmployeeApi, PER_PAGE} from '../../api/employeeService';

interface TreeNode extends DataNode {
    title: string | JSX.Element;
    key: string;
    children?: TreeNode[];
}

const EmployeeTree: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, void, EmployeeActionTypes>>();
    const navigate = useNavigate();
    const { data: employees = [], loading, total, links, pagination } = useSelector((state: RootState) => state.employees);

    const [currentPage, setCurrentPage] = useState(1);
    const per_page = PER_PAGE;

    useEffect(() => {
        dispatch(fetchEmployeesAction(currentPage, per_page));
    }, [dispatch, currentPage]);

    const addChildren = (current: Employee, allEmployees: Employee[]): void => {
        const id = current.id;

        const children = current.subordinates

        if (children && children.length > 0) {
            current.children = children;
            children.forEach(child => addChildren(child, allEmployees));
        }
    };

    const buildEmployeeTree = (employees: Employee[]): Employee[] => {
        const rootEmployees = employees.filter(emp => emp.manager_id === null);

        rootEmployees.forEach(root => addChildren(root, employees));

        return rootEmployees;
    };


    const buildTree = (employees: Employee[], parentId: number | null): TreeNode[] => {
        return employees
            .filter(emp => emp.manager_id === parentId)
            .map(emp => ({
                title: (
                    <div className="employee-node">
                        <span className="employee-label">{`${emp.id} __ ${emp.first_name} ${emp.last_name} - ${emp.position}`}</span>
                        <div className="employee-icons">
                            <FontAwesomeIcon
                                icon={faEdit}
                                className="icon-btn edit"
                                onClick={() => navigate(`/employees/${emp.id}/edit`)}
                            />
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="icon-btn delete"
                                onClick={() => handleDelete(emp.id)}
                            />
                        </div>
                    </div>
                ),
                key: emp.id.toString(),
                children: buildTree(emp.children || [], emp.id),
            }));
    };

    const handleDelete =  (id: number) => {
        console.log("HERE DELETE");
        if(window.confirm('Are you sure you want to delete this record?')){
            try {
                console.log("delete", id);
                const response = deleteEmployeeApi(id)
                response.then(()=> {
                        dispatch(fetchEmployeesAction(currentPage, per_page));
                    }
                ).catch((error)=>{
                    console.error('Failed to delete employee:', error);
                })
            } catch (error) {
                console.error('Failed to delete employee:', error);
            }

        }
    };

    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= (pagination?.total_pages || 1)) {
            setCurrentPage(page);
        }
    };

    const total_pages = pagination?.total_pages || 1;
    const current_page = pagination?.current_page || 0;




    if (loading) return <div>Loading...</div>;

    const treeDataPrepare = buildEmployeeTree(employees);
    const treeData = buildTree(treeDataPrepare, null);



    return (
        <div>
            <div className="employee-tree-header">
                <button
                    className="create-employee-btn"
                    onClick={() => navigate('/employees/new')}
                >
                    <FontAwesomeIcon icon={faPlus} /> Create Employee
                </button>
            </div>
            <Tree treeData={treeData} />
            <div className="pagination">
                {pagination && (
                    <Pagination className="pagination">
                        {current_page > 1 && (
                            <Pagination.Prev onClick={() => handlePageChange(current_page - 1)} />
                        )}
                        {current_page > 2 && (
                            <>
                                <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                                {current_page > 3 && <Pagination.Ellipsis />}
                            </>
                        )}
                        {current_page > 1 && (
                            <Pagination.Item onClick={() => handlePageChange(current_page - 1)}>
                                {current_page - 1}
                            </Pagination.Item>
                        )}
                        <Pagination.Item active>{current_page}</Pagination.Item>
                        {current_page < total_pages && (
                            <Pagination.Item onClick={() => handlePageChange(current_page + 1)}>
                                {current_page + 1}
                            </Pagination.Item>
                        )}
                        {current_page < total_pages - 1 && (
                            <>
                                {current_page < total_pages - 2 && <Pagination.Ellipsis />}
                                <Pagination.Item onClick={() => handlePageChange(total_pages)}>
                                    {total_pages}
                                </Pagination.Item>
                            </>
                        )}
                        {current_page < total_pages && (
                            <Pagination.Next onClick={() => handlePageChange(current_page + 1)} />
                        )}
                    </Pagination>
                )}
            </div>
        </div>
    );
};

export default EmployeeTree;
