import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createEmployeeAction, updateEmployeeAction, resetEmployeeSuccess, fetchEmployeeAction } from '../../redux/actions/employeeActions';
import { RootState } from '../../redux/reducers';
import { useNavigate, useParams } from 'react-router-dom';
import {Employee, ErrorResponse} from "../../redux/types";
import {handleAxiosError} from "../../helpers/handleAxiosError";
import {AxiosError} from "axios";

const EmployeeForm: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams<{ id?: string }>();
    const isEditMode = !!id;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const employee = useSelector((state: RootState) =>
        isEditMode ? state.employees.currentEmployee : null
    );

    const [formData, setFormData] = useState<Employee>({
        id: 0,
        first_name: '',
        last_name: '',
        position: '',
        email: '',
        home_phone: '',
        notes: '',
        manager_id: null,
        children: [],
        subordinates: [],
        manager: [],
        linkId: 0,
        unlinkId: 0,
        linkType: '',
    });

    const success = useSelector((state: RootState) => state.employees.success);
    const errorEmployee = useSelector((state: RootState) => state.employees.error);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (id) {
            dispatch(fetchEmployeeAction(parseInt(id)));
            return () => {
                console.log("RESET");
                dispatch(resetEmployeeSuccess());
            };

        }
    }, [id, dispatch]);

    useEffect(() => {
        if (employee) {
            setFormData(employee);
            setLoading(false);
        }
    }, [employee]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.name === "linkId" || e.target.name === "unlinkId"
                ? (e.target.value === '' ? 0 : parseInt(e.target.value))
                : e.target.name === "linkType"
                    ? e.target.value
                    : e.target.value
        });
    };



    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const { linkType, linkId, unlinkId, ...rest } = formData;

        const dataToSubmit: Partial<Employee> = { ...rest };

        if (linkType !== '') {
            dataToSubmit.linkType = linkType;
        }
        if (linkId !== 0) {
            dataToSubmit.linkId = linkId;
        }
        if (unlinkId !== 0) {
            dataToSubmit.unlinkId = unlinkId;
        }

        if (isEditMode) {
            dispatch(updateEmployeeAction(formData.id, dataToSubmit));
        } else {
            await dispatch(createEmployeeAction(dataToSubmit));
        }
    };

    useEffect(() => {
        if (success) {
            navigate('/employees');
            dispatch(resetEmployeeSuccess());
        }
    }, [success, navigate]);

    useEffect(() => {
        if (errorEmployee) {
            setErrorMessage(errorEmployee);
        }
    }, [errorEmployee]);

    return (
        <div className="container mt-5">
            <h1 className="mb-4">{isEditMode ? 'Edit Employee' : 'Create Employee'}</h1>
            {errorEmployee && !success && handleAxiosError(errorEmployee as AxiosError<ErrorResponse> | null)}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="first_name" className="form-label">First Name</label>
                    <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        className="form-control"
                        value={formData.first_name || ''}
                        onChange={handleChange}
                        placeholder="First Name"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="last_name" className="form-label">Last Name</label>
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        className="form-control"
                        value={formData.last_name || ''}
                        onChange={handleChange}
                        placeholder="Last Name"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="position" className="form-label">Position</label>
                    <input
                        type="text"
                        id="position"
                        name="position"
                        className="form-control"
                        value={formData.position || ''}
                        onChange={handleChange}
                        placeholder="Position"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        value={formData.email || ''}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="home_phone" className="form-label">Phone</label>
                    <input
                        type="tel"
                        id="home_phone"
                        name="home_phone"
                        className="form-control"
                        value={formData.home_phone || ''}
                        onChange={handleChange}
                        placeholder="Phone"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        id="notes"
                        name="notes"
                        className="form-control"
                        value={formData.notes || ''}
                        onChange={handleChange}
                        placeholder="Notes"
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="linkType" className="form-label">Link Type</label>
                    <select
                        id="linkType"
                        name="linkType"
                        className="form-control"
                        value={formData.linkType || ''}
                        onChange={handleChange}
                    >
                        <option value="">Не выбрано</option>
                        <option value={0}>Manager</option>
                        <option value={1}>Subordinates</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="linkId" className="form-label">Add Link (ID)</label>
                    <input
                        type="number"
                        id="linkId"
                        name="linkId"
                        className="form-control"
                        value={formData.linkId || ''}
                        onChange={handleChange}
                        placeholder="ID of Manager or Subordinate"
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="unlinkId" className="form-label">Remove Link (ID)</label>
                    <input
                        type="number"
                        id="unlinkId"
                        name="unlinkId"
                        className="form-control"
                        value={formData.unlinkId || ''}
                        onChange={handleChange}
                        placeholder="ID of Manager or Subordinate"
                    />
                </div>

                <button type="submit" className="btn btn-primary">
                    {isEditMode ? 'Update Employee' : 'Create Employee'}
                </button>
            </form>
        </div>
    );
};

export default EmployeeForm;
