import { Dispatch } from 'redux';
import api from '../../api/axiosConfig';


export const login = (credentials: { email: string; password: string }) => async (dispatch: Dispatch) => {
    dispatch({ type: 'LOGIN_REQUEST' });
    try {
        const response = await api.post('/auth/login', credentials);
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('user_id', response.data.user_id);
    } catch (error: any) {
        dispatch({ type: 'LOGIN_FAIL', payload: error.response.data });
    }
};

export const logout = () => async (dispatch: Dispatch) => {
    dispatch({ type: 'LOGOUT_REQUEST' });
    try {
        await api.post('/auth/logout');
        dispatch({ type: 'LOGOUT_SUCCESS' });
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
    } catch (error: any) {
        dispatch({ type: 'LOGOUT_FAIL', payload: error.response.data });
    }
};


export const checkAuthStatus = (token: string) => ({
    type: 'CHECK_AUTH_STATUS',
    token
});

