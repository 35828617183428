import { ThunkAction } from 'redux-thunk';
import {RootState} from "../reducers";
import {fetchEmployeesApi} from "../../api/employeeService";


export const FETCH_EMPLOYEES_REQUEST = 'FETCH_EMPLOYEES_REQUEST';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAIL = 'FETCH_EMPLOYEES_FAIL';
export const FETCH_EMPLOYEE_FAIL = 'FETCH_EMPLOYEE_FAIL';
export const CREATE_EMPLOYEE_REQUEST = 'CREATE_EMPLOYEE_REQUEST';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAIL = 'CREATE_EMPLOYEE_FAIL';
export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAIL = 'UPDATE_EMPLOYEE_FAIL';
export const DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAIL = 'DELETE_EMPLOYEE_FAIL';
export const RESET_EMPLOYEE_SUCCESS = 'RESET_EMPLOYEE_SUCCESS';

export const FETCH_EMPLOYEE_REQUEST = 'FETCH_EMPLOYEE_REQUEST';

export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';







export const fetchEmployeesAction = (page: number, per_page: number): ThunkAction<void, RootState, unknown, EmployeeActionTypes> => async (dispatch) => {
    dispatch({ type: FETCH_EMPLOYEES_REQUEST, page, per_page });
    try {
        const response = await fetchEmployeesApi(page, per_page);
        console.log("ACTION ALL", response);
        dispatch({ type: FETCH_EMPLOYEES_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: FETCH_EMPLOYEES_FAIL, payload: error });
    }
};

export const fetchEmployeeAction = (id: number) => ({
    type: FETCH_EMPLOYEE_REQUEST,
    id
});


export const createEmployeeAction = (employeeData: any) => ({
    type: CREATE_EMPLOYEE_REQUEST,
    employeeData,

});

export const updateEmployeeAction = (id: number, employeeData: any) => ({
    type: UPDATE_EMPLOYEE_REQUEST,
    id,
    employeeData,

});

export const deleteEmployeeAction = (id: number) => ({
    type: DELETE_EMPLOYEE_REQUEST,
    id,
});

export const resetEmployeeSuccess = () => ({
    type: RESET_EMPLOYEE_SUCCESS,
});


export type EmployeeActionTypes =
    | { type: typeof FETCH_EMPLOYEES_REQUEST; page: number; per_page: number }
    | { type: typeof FETCH_EMPLOYEES_SUCCESS; payload: any }
    | { type: typeof FETCH_EMPLOYEES_FAIL; payload: any }
    | { type: typeof CREATE_EMPLOYEE_REQUEST; employeeData: any }
    | { type: typeof CREATE_EMPLOYEE_SUCCESS; payload: any }
    | { type: typeof CREATE_EMPLOYEE_FAIL; payload: any }
    | { type: typeof UPDATE_EMPLOYEE_REQUEST; id: number; employeeData: any }
    | { type: typeof UPDATE_EMPLOYEE_SUCCESS; payload: any }
    | { type: typeof UPDATE_EMPLOYEE_FAIL; payload: any }
    | { type: typeof DELETE_EMPLOYEE_REQUEST; id: number }
    | { type: typeof FETCH_EMPLOYEE_REQUEST; id: number }
    | { type: typeof FETCH_EMPLOYEE_SUCCESS; paylod: any }
    | { type: typeof DELETE_EMPLOYEE_SUCCESS; payload: { id: number } }
    | { type: typeof DELETE_EMPLOYEE_FAIL; payload: any };
