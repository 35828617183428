import {Employee, PaginationData} from '../redux/types';
import api from './axiosConfig';
import {Pagination} from "react-bootstrap";

export const fetchEmployeesApi = async (page: number, per_page: number): Promise<{ data: Employee[], total: number, links: any, pagination: PaginationData }> => {

    const response = await api.get('/employees', { params: { page, per_page }, withCredentials: true });
   console.log("resp", response);
    const { data, total, links, pagination} = response.data;
    return { data, total, links, pagination};
};
export const fetchEmployeesSearchApi = async (query: string, employee_id: number): Promise<{ data: Employee[] }> => {
    const params: any = {};
    params.query = query;
    params.employee_id = employee_id;

    const response = await api.get('/managers', {
        params
    });

   return response.data;

};


export const fetchEmployeeApi = async (id: number): Promise<{ data: Employee}> => {
    const response = await api.get(`/employees/${id}`);

    return response.data;
};


export const createEmployeeApi = async (employeeData: any) => {
    const response = await api.post('/employees', employeeData);
    return response.data;
};

export const updateEmployeeApi = async (id: number, employeeData: any) => {
    const response = await api.put(`/employees/${id}`, employeeData);
    return response.data;
};

;

export const deleteEmployeeApi = async (id: number) => {

    await api.delete(`/employees/${id}`);
};

export const PER_PAGE = 50;
export const MAX_VISIBLE_PAGES = 10;
