import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import {
    FETCH_EMPLOYEES_REQUEST,
    CREATE_EMPLOYEE_REQUEST,
    UPDATE_EMPLOYEE_REQUEST,
    DELETE_EMPLOYEE_REQUEST,
    FETCH_EMPLOYEE_REQUEST,
    FETCH_EMPLOYEES_SUCCESS,
    FETCH_EMPLOYEES_FAIL,
    CREATE_EMPLOYEE_SUCCESS,
    CREATE_EMPLOYEE_FAIL,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAIL,
    DELETE_EMPLOYEE_SUCCESS,
    DELETE_EMPLOYEE_FAIL,
} from '../actions/employeeActions';
import {fetchEmployeesApi, createEmployeeApi, updateEmployeeApi, deleteEmployeeApi, fetchEmployeeApi} from '../../api/employeeService';
import { FetchEmployeesResponse } from "../types";



interface FetchEmployeesAction {
    type: typeof FETCH_EMPLOYEES_REQUEST;
    page: number;
    per_page: number;
}

interface CreateEmployeeAction {
    type: typeof CREATE_EMPLOYEE_REQUEST;
    employeeData: any;
    link_id: number;
    unlink_id: number
}

interface UpdateEmployeeAction {
    type: typeof UPDATE_EMPLOYEE_REQUEST;
    id: number;
    employeeData: any;
    link_id: number;
    unlink_id: number;
}

interface DeleteEmployeeAction {
    type: typeof DELETE_EMPLOYEE_REQUEST;
    id: number;

}

interface FetchEmployeeAction {
    type: typeof FETCH_EMPLOYEE_REQUEST;
    id: number;
}

function* fetchEmployeesSaga(action: FetchEmployeesAction): Generator<CallEffect<FetchEmployeesResponse> | PutEffect<any>, void, FetchEmployeesResponse> {
    try {
        const { page, per_page } = action;
        const response: FetchEmployeesResponse = yield call(fetchEmployeesApi, page, per_page);
        console.log("resp saga all", response);
        yield put({ type: FETCH_EMPLOYEES_SUCCESS, payload: response });
    } catch (error: any) {
        yield put({ type: FETCH_EMPLOYEES_FAIL, payload: error.message });
    }
}

function* createEmployeeSaga(action: CreateEmployeeAction): Generator<CallEffect<any> | PutEffect<any>, void, any> {
    try {
        const response: any = yield call(createEmployeeApi, action.employeeData);
        yield put({ type: CREATE_EMPLOYEE_SUCCESS, payload: response });
    } catch (error: any) {
        console.log("CREATE FAIL SAGA", error);
        yield put({ type: CREATE_EMPLOYEE_FAIL, payload: error });
    }
}

function* updateEmployeeSaga(action: UpdateEmployeeAction): Generator<CallEffect<any> | PutEffect<any>, void, any> {
    try {

        const response: any = yield call(updateEmployeeApi, action.id, action.employeeData);
        console.log("resp saga upd", response);
        yield put({ type: UPDATE_EMPLOYEE_SUCCESS, payload: response });
    } catch (error: any) {
        console.log("UPDATE FAIL SAGA", error);
        yield put({ type: UPDATE_EMPLOYEE_FAIL, payload: error });
    }
}

function* deleteEmployeeSaga(action: DeleteEmployeeAction): Generator<CallEffect<void> | PutEffect<any>, void, void> {
    try {
        console.log("SAGS DELETE")
        yield call(deleteEmployeeApi, action.id);
        yield put({ type: DELETE_EMPLOYEE_SUCCESS, payload: { id: action.id } });
    } catch (error: any) {
        yield put({ type: DELETE_EMPLOYEE_FAIL, payload: error.message });
    }
}
function* fetchEmployeeSaga(action: any): Generator {
    try {

        const employee = yield call(fetchEmployeeApi, action.id);
        console.log("SAGA", employee);
        yield put({ type: 'FETCH_EMPLOYEE_SUCCESS', payload: employee });
    } catch (error) {
        console.log("Fetching ERROR employee with ID:", action);
        yield put({ type: 'FETCH_EMPLOYEE_FAIL', payload: error });
    }
}


export default function* employeeSaga() {
    yield takeLatest(FETCH_EMPLOYEES_REQUEST, fetchEmployeesSaga);
    yield takeLatest(CREATE_EMPLOYEE_REQUEST, createEmployeeSaga);
    yield takeLatest(UPDATE_EMPLOYEE_REQUEST, updateEmployeeSaga);
    yield takeLatest(DELETE_EMPLOYEE_REQUEST, deleteEmployeeSaga);
    yield takeLatest(FETCH_EMPLOYEE_REQUEST, fetchEmployeeSaga);


}
