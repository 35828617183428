import {BrowserRouter as Router, Routes, Route, useNavigate} from 'react-router-dom';
import Navbar from "./components/Navbar";
import Login from './components/Auth/Login';
import PrivateRoute from './components/PrivateRoute';
import Logout from "./components/Auth/Logout";
import EmployeeTree from "./components/Employee/EmployeeTree";
import EmployeeForm from "./components/Employee/EmployeeForm";

const App: React.FC = () => {

    return (

            <Router>
                <div>
                    <Navbar />
                    <div className="container mt-4">
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/logout" element={<PrivateRoute element={<Logout />} />} />

                            <Route path="/employees" element={<EmployeeTree />} />
                            <Route path="/employees/new" element={<EmployeeForm />} />
                            <Route path="/employees/:id/edit" element={<EmployeeForm />} />

                        </Routes>

                    </div>
                </div>
            </Router>



);
};
export default App;





