interface AuthState {
    isAuthenticated: boolean;
    user: any | null;
    loading: boolean;
    error: any | null;
    user_id: number | null,
}

const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null,
    user_id: null,
};

export default (state = initialState, action: any): AuthState => {
    switch (action.type) {
        case 'CHECK_AUTH_STATUS_SUCCESS':
            return {
                ...state,
                isAuthenticated: action.payload
            };
        case 'CHECK_AUTH_STATUS_FAIL':
            return {
                ...state,
                isAuthenticated: false
            };
        case 'LOGIN_REQUEST':
        case 'LOGOUT_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'LOGIN_SUCCESS':
            console.log(action, "LOG", action.payload.user_id);
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                loading: false,
                error: null,
                user_id: action.payload.user_id,

            };
        case 'LOGOUT_SUCCESS':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                loading: false,
                error: null,
                user_id: null
            };
        case 'LOGIN_FAIL':
        case 'LOGOUT_FAIL':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


