import {Employee, PaginationData} from '../types';
import {
    FETCH_EMPLOYEES_REQUEST,
    FETCH_EMPLOYEES_SUCCESS,
    FETCH_EMPLOYEES_FAIL,
    CREATE_EMPLOYEE_REQUEST,
    CREATE_EMPLOYEE_SUCCESS,
    CREATE_EMPLOYEE_FAIL,
    UPDATE_EMPLOYEE_REQUEST,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAIL,
    DELETE_EMPLOYEE_REQUEST,
    DELETE_EMPLOYEE_SUCCESS,
    DELETE_EMPLOYEE_FAIL,
    RESET_EMPLOYEE_SUCCESS,
    FETCH_EMPLOYEE_REQUEST,
    FETCH_EMPLOYEE_SUCCESS
} from '../actions/employeeActions';

interface EmployeeState {
    data: Employee[];
    loading: boolean;
    error: any | null;
    total: number;
    links: Array<{ url: string | null, label: string, active: boolean }>;
    success: boolean;
    currentEmployee: Employee | null,
    subordinates: Employee[] | null,
    managers: Employee[] | null,
    linkId: number | null,
    unlinkId: number | null,
    pagination: PaginationData | null,
}


const initialState: EmployeeState = {
    data: [],
    loading: false,
    error: null,
    total: 0,
    links: [],
    success: false,
    currentEmployee: null,
    subordinates: null,
    managers: null,
    linkId: null,
    unlinkId: null,
    pagination: null,
};

const employeeReducer = (state = initialState, action: any): EmployeeState => {
    switch (action.type) {
        case FETCH_EMPLOYEES_REQUEST:
            console.log("REQUEST RED ALL", action);
            return { ...state, loading: true };
        case FETCH_EMPLOYEES_SUCCESS:
            console.log("PAYLOAD RED ALL", action);
            return { ...state, loading: false, data: action.payload.data, total: action.payload.total,
                links: action.payload.links, pagination: action.payload.pagination };
        case FETCH_EMPLOYEES_FAIL:
            return { ...state, loading: false, error: action.payload };
        case CREATE_EMPLOYEE_SUCCESS:
            return { ...state, success: true, data: [...state.data, action.payload] };
        case CREATE_EMPLOYEE_FAIL:
            console.log("CREATE FAIL RED", action);
            return { ...state, error: action.payload, loading: false };
        case UPDATE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                success: true,
                data: state.data.map(emp => emp.id === action.payload.id ? action.payload : emp)
            };
        case UPDATE_EMPLOYEE_FAIL:
            console.log("UPDATE FAIL RED", action);
            return { ...state, error: action.payload, loading: false};
        case DELETE_EMPLOYEE_SUCCESS:
            return { ...state, data: state.data.filter(emp => emp.id !== action.payload) };
        case DELETE_EMPLOYEE_FAIL:
            return { ...state, error: action.payload };
        case RESET_EMPLOYEE_SUCCESS:
            return { ...state, success: false, error: null};
        case FETCH_EMPLOYEE_REQUEST:
            return { ...state, loading: true };
        case FETCH_EMPLOYEE_SUCCESS:
            return { ...state, currentEmployee: action.payload, loading: false };
        default:
            return state;
    }
};

export default employeeReducer;
