import React, {useEffect} from 'react';
import { useDispatch} from 'react-redux';
import { logout} from "../../redux/actions/AuthActions";
import { AppDispatch } from '../../redux/store';
import {useNavigate} from "react-router-dom";

const Logout: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logout());
        navigate('/login');
    }, [dispatch, navigate]);

    return null;
};

export default Logout;



