
interface Errors {
    [key: string]: string[] | string;
}
export const renderErrors = (errors: any) => {
    if (!errors || typeof errors !== 'object' || Array.isArray(errors)) {
        return null;
    }

    return (
        <>
            {Object.keys(errors).map((key) => {
                const errorMessages = Array.isArray(errors[key]) ? errors[key] : [errors[key]];

                return (
                    <div key={key}>
                        {errorMessages.map((err: string, index: number) => (
                            <div key={index} className="error">
                                {err}
                            </div>
                        ))}
                    </div>
                );
            })}
        </>
    );
};


