import { takeLatest, call, put } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { AuthResponse, ErrorResponse, RegisterPayload, LoginPayload } from '../types';
import api from "../../api/axiosConfig";
import {isTokenValid} from "../../components/Auth/authUtils";


const REGISTER_REQUEST = 'REGISTER_REQUEST';
const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
const REGISTER_FAIL = 'REGISTER_FAIL';

const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAIL = 'LOGIN_FAIL';

const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'LOGOUT_FAIL';

// Регистрация
function* registerSaga(action: AnyAction): Generator<any, void, AxiosResponse<AuthResponse>> {
    try {
        const res: AxiosResponse<AuthResponse> = yield call(api.post, '/auth/register', action.payload as RegisterPayload);
        yield put({ type: REGISTER_SUCCESS, payload: res.data });
    } catch (error) {
        const axiosError = error as AxiosError<ErrorResponse>;
        yield put({ type: REGISTER_FAIL, payload: axiosError.response?.data });
    }
}

function* loginSaga(action: AnyAction): Generator<any, void, AxiosResponse<AuthResponse>> {
    try {
        const res: AxiosResponse<AuthResponse> = yield call(api.post, '/auth/login', action.payload as LoginPayload);
        yield put({ type: LOGIN_SUCCESS, payload: res.data });
    } catch (error) {
        const axiosError = error as AxiosError<ErrorResponse>;
        yield put({ type: LOGIN_FAIL, payload: axiosError.response?.data });
    }
}


function* logoutSaga(): Generator<any, void, AxiosResponse<{}>> {
    try {
        yield call(api.post, '/auth/logout');
        yield put({ type: LOGOUT_SUCCESS });
    } catch (error) {
        const axiosError = error as AxiosError<ErrorResponse>;
        yield put({ type: LOGOUT_FAIL, payload: axiosError.response?.data });
    }
}

export default function* authSaga() {
    yield takeLatest(REGISTER_REQUEST, registerSaga);
    yield takeLatest(LOGIN_REQUEST, loginSaga);
    yield takeLatest(LOGOUT_REQUEST, logoutSaga);
    yield takeLatest('CHECK_AUTH_STATUS', checkAuthStatusSaga);
}

function* checkAuthStatusSaga(action: any): Generator {
    try {
        const isValid = yield call(isTokenValid, action.token);
        yield put({ type: 'CHECK_AUTH_STATUS_SUCCESS', payload: isValid });
    } catch (error) {
        yield put({ type: 'CHECK_AUTH_STATUS_FAIL', payload: error });
    }
}





