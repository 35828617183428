import React, {useEffect, useState} from 'react';
import { Navigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { RootState} from "../redux/reducers";
import {checkAuthStatus} from "../redux/actions/AuthActions";

interface PrivateRouteProps {
    element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            dispatch(checkAuthStatus(token))

        } else {
            setLoading(false);
        }
    }, []);
    return isAuthenticated ? element : <Navigate to="/login" replace />;
};

export default PrivateRoute;






